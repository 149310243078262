<template>
  <base-modal
    v-model="model"
    :close-on-click-outside="true"
    title="Opret gruppe"
    :can-overflow="true"
  >
    <base-input v-model="form.name" type="text" label="Navn" :required="true" tabindex="1"></base-input>

    <base-input label="Valgfrit: Vælg en eksisterende gruppe, hvor din nye gruppe skal placeres som en undergruppe" class="mt-4" >
      <base-select
        slot="input"
        v-model="form.parentGroupId"
        width="w-full"
        :clearable="true"
        :options="groupOptions"
        tabindex="2"
        :value="form.parentGroupId || ''"
        @input="val => form.parentGroupId = val || null"
        placeholder="Vælg eksisterende gruppe"
      >
        <template v-slot:option="{ option }">
          <span class="option-label">{{ formatOptionLabel(option.label) }}</span>
        </template>
      </base-select>
    </base-input>

    <base-alert
        v-if="form.parentGroupId"
        title="Den valgte gruppe vil blive overgruppe for den nye gruppe."
        type="info"
        class="mt-4"
    ></base-alert>

    <div slot="footer" class="flex justify-end">
      <base-button tabindex="4" color="indigo" :disabled="$v.form.$invalid" @click="submit">Opret</base-button>
    </div>
  </base-modal>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import vuelidateMixin from '@/mixins/vuelidateMixin';
import { mapGetters } from 'vuex';

export default {
  name: 'WorkspaceCreateGroupModal',
  mixins: [vuelidateMixin],
  props: {
    value: {
      type: Boolean,
    },
  },
  data() {
    return {
      form: {
        name: '',
        parentGroupId: '',
      },
    };
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(show) {
        this.$emit('input', show);
      },
    },
    ...mapGetters('departments', ['groupOptions']),
  },
  methods: {
    submit() {
      if (this.$v.form.$invalid) return;

      const payload = {
        name: this.form.name,
        parentGroupId: this.form.parentGroupId || null,
      };

      this.$emit('create', payload);
      this.resetForm();
    },
    resetForm() {
      this.form.name = '';
      this.form.parentGroupId = '';
      this.$v.$reset();
    },
    formatOptionLabel(label) {
      const parts = label.split('/');
      return parts.map((part, index) => `${'  '.repeat(index)}${part}`).join('\n');
    },
  },
  validations() {
    return {
      form: {
        name: { required },
      },
    };
  },
};
</script>

<style scoped>
.option-label {
  white-space: pre-wrap;
}
</style>
