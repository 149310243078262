<template>
  <li class="relative bg-white my-2 group overflow-hidden">
    <div class="flex justify-between w-full py-6 px-6 cursor-pointer" @click="toggleOpen">
      <div class="flex items-center">
        <icon-folder v-if="isTopLevelGroup" class="h-6 w-6 mr-2 text-indigo-600"></icon-folder>
        <icon-sub-folder v-else class="h-6 w-6 mr-2 text-indigo-600"></icon-sub-folder>
        <span v-html="$options.filters.highlight(group.name, searchFilter)"></span>
      </div>
      <div class="ml-6 flex items-center">
        <span v-if="isEmptyGroup" class="text-sm text-gray-500">(Tom gruppe)</span>
        <template v-else>
          <icon-plus v-if="!isOpen" class="h-5 w-5"></icon-plus>
          <icon-minus v-else class="h-5 w-5"></icon-minus>
        </template>
      </div>
    </div>

    <collapse-transition>
      <div v-if="isOpen" class="pl-6">
        <!-- Render workspaces within this group -->
        <workspace-list-item
            v-for="ws in group.workspaces"
            :key="ws.id"
            :workspace="ws"
            :search-filter="searchFilter"
            @setWorkspace="$emit('setWorkspace', $event)"
            @deleteWorkspace="$emit('deleteWorkspace', $event)"
            @openAssignWorkspace="$emit('openAssignWorkspace', $event)"
        ></workspace-list-item>

        <!-- Recursively render nested groups -->
        <workspace-list-group-item
            v-for="nestedGroup in group.groups"
            :key="nestedGroup.id"
            :group="nestedGroup"
            :search-filter="searchFilter"
            :is-subfolder="true"
            @setWorkspace="$emit('setWorkspace', $event)"
            @deleteWorkspace="$emit('deleteWorkspace', $event)"
            @openAssignWorkspace="$emit('openAssignWorkspace', $event)"
        ></workspace-list-group-item>
      </div>
    </collapse-transition>
  </li>
</template>

<script>
import { CollapseTransition } from 'vue2-transitions';
import WorkspaceListItem from './WorkspaceListItem.vue';

export default {
  name: 'WorkspaceListGroupItem',
  components: {
    WorkspaceListItem,
    CollapseTransition,
  },
  props: {
    group: {
      type: Object,
      required: true,
    },
    searchFilter: {
      type: String,
      default: '',
    },
    isSubfolder: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  computed: {
    isEmptyGroup() {
      return (!this.group.workspaces || this.group.workspaces.length === 0)
          && (!this.group.groups || this.group.groups.length === 0);
    },
    isTopLevelGroup() {
      return !this.isSubfolder;
    },
  },
  watch: {
    searchFilter: {
      immediate: true,
      handler(newSearch) {
        this.isOpen = !!newSearch;
      },
    },
  },
  methods: {
    toggleOpen() {
      if (!this.isEmptyGroup) {
        this.isOpen = !this.isOpen;
      }
    },
  },
};
</script>
