<template>
  <base-modal
      v-if="isDataLoaded"
      v-model="model"
      :close-on-click-outside="true"
      title="Flyt gruppe til anden gruppe"
      :can-overflow="true"
  >
    <base-input label="Vælg gruppe at flytte" class="mt-4">
      <base-select
          slot="input"
          v-model="form.id"
          width="w-full"
          :clearable="false"
          :options="groupOptions"
          tabindex="1"
          placeholder="Vælg gruppe at flytte"
      ></base-select>
    </base-input>

    <base-alert
        v-if="selectedGroup"
        title="Denne handling vil flytte følgende gruppe struktur:"
        type="warning"
        class="mt-4"
    >
      <div class="hierarchy-view">
        <group-hierarchy-view :item="moveHierarchy" />
      </div>
    </base-alert>

    <base-alert
        title="Vælg enten en eksisterende gruppe eller flyt til rodniveau"
        type="info"
        class="mt-4"
    ></base-alert>

    <base-input label="Vælg eksisterende gruppe" class="mt-4">
      <base-select
          slot="input"
          v-model="form.targetGroupId"
          width="w-full"
          :clearable="true"
          :options="availableGroupOptions"
          tabindex="2"
          placeholder="Vælg en mappe at flytte til"
          @input="onExistingGroupSelect"
          :disabled="form.moveToRoot"
      ></base-select>
    </base-input>

    <base-checkbox
        v-model="form.moveToRoot"
        label="Flyt ud som hovedgruppe"
        class="mt-4"
        @input="onMoveToRootChange"
        :disabled="!!form.targetGroupId"
    />

    <p v-if="errorMessage" class="text-red-500 mt-2">{{ errorMessage }}</p>

    <div slot="footer" class="flex justify-end">
      <base-button
          tabindex="3"
          color="indigo"
          :disabled="!isFormValid"
          @click="submit"
      >Flyt gruppe</base-button>
    </div>
  </base-modal>
</template>

<script>
import { mapGetters } from 'vuex';
import GroupHierarchyView from '@/components/GroupHierachyView';

export default {
  name: 'WorkspaceMoveGroupModal',
  components: {
    GroupHierarchyView,
  },
  props: {
    value: {
      type: Boolean,
    },
  },
  data() {
    return {
      form: {
        id: '',
        targetGroupId: '',
        moveToRoot: false,
      },
      errorMessage: '',
    };
  },
  computed: {
    moveHierarchy() {
      if (!this.selectedGroup) return null;
      return this.buildHierarchy(this.selectedGroup);
    },
    isDataLoaded() {
      return Array.isArray(this.groupOptions) && this.groupOptions.length > 0;
    },
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    ...mapGetters('departments', ['groupOptions', 'getGroupById']),
    selectedGroup() {
      if (!this.form.id) return null;
      const group = this.getGroupById(this.form.id);
      return group || null;
    },
    availableGroupOptions() {
      if (!Array.isArray(this.groupOptions)) {
        return [];
      }
      return this.groupOptions.filter((option) => option && option.value !== this.form.id
          && (!this.selectedGroup || !this.isSubgroup(option.value, this.selectedGroup)));
    },
    isFormValid() {
      return this.form.id && (this.form.targetGroupId || this.form.moveToRoot) && !this.errorMessage;
    },
  },
  watch: {
    form: {
      handler: 'validateForm',
      deep: true,
    },
  },
  methods: {
    async submit() {
      if (!this.isFormValid) return;
      this.$emit('assigned', {
        groupId: this.form.id,
        targetGroupId: this.form.moveToRoot ? null : this.form.targetGroupId,
        moveToRoot: this.form.moveToRoot,
      });
    },
    isSubgroup(potentialSubgroupId, parentGroup) {
      if (!parentGroup || !parentGroup.groups || !Array.isArray(parentGroup.groups)) return false;
      // eslint-disable-next-line no-mixed-operators
      return parentGroup.groups.some((subgroup) => subgroup && subgroup.id === potentialSubgroupId
          // eslint-disable-next-line no-mixed-operators
          || this.isSubgroup(potentialSubgroupId, subgroup));
    },
    validateForm() {
      if (this.form.moveToRoot) {
        this.errorMessage = '';
      } else if (this.form.targetGroupId === this.form.id) {
        this.errorMessage = 'En gruppe kan ikke flyttes ind i sig selv.';
      } else if (this.isSubgroup(this.form.targetGroupId, this.selectedGroup)) {
        this.errorMessage = 'En gruppe kan ikke flyttes ind i en af sine egne undergrupper.';
      } else {
        this.errorMessage = '';
      }
    },
    buildHierarchy(group) {
      const result = {
        name: group.name,
        type: 'group',
        children: [],
      };

      if (group.workspaces && group.workspaces.length > 0) {
        group.workspaces.forEach((workspace) => {
          result.children.push({
            name: workspace.name,
            type: 'workspace',
          });
        });
      }

      if (group.groups && group.groups.length > 0) {
        group.groups.forEach((subgroup) => {
          result.children.push(this.buildHierarchy(subgroup));
        });
      }

      return result;
    },
  },
};
</script>
